import { Component, EventEmitter, ChangeDetectorRef, AfterViewInit, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ButtonLayoutType } from '../../../shared/_constants/button-layout-type.const';
import { ButtonColor } from '../../../shared/_constants/button-color.const';

import { NavigateService } from '../../../shared/_services/navigate.service';
@Component({
  selector: 'fpc-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit, AfterViewInit {

  squaredStyle: boolean = false;
  isHover: boolean = false;
  isBold: boolean = false;

  @Input() id: string = (Math.floor(Math.random() * 100) + 1).toString();
  @Input() altText!: string;
  @Input() badgeText!: string;
  @Input() buttonColorClass!: ButtonColor;
  @Input() buttonClass: string | string[] = '';
  @Input() buttonHoverClass: string;
  @Input() buttonLayoutType: ButtonLayoutType = "link";
  @Input() active: boolean = false;
  @Input() iconLayout: boolean = false;

  @Input() customNgStyle: any;
  @Input() customStyle: any;
  @Input() customNgClass!: | string | string[] | Set<string> | { [Klass: string]: any };
  @Input() disabled: boolean = false;
  @Input() text: any;
  @Input() type: string = 'button';
  @Input() isDownload!: boolean;
  @Input() link!: string;
  @Input() target: '_self' | '_blank';
  @Input() truncate: boolean = false;
  @Input() popoverText: string = 'You see, I show up on hover!';

  @Input() iconClass!: string[] | string | undefined;
  @Input() iconHoverClass!: string | undefined;
  @Input() iconStyle!: any;
  @Input() iconSize: 'lg' | 'md' | 'sm' | undefined;

  @Input() dataToggle!: string;
  @Input() dataTarget!: string;
  @Input() dataDismiss!: string;
  @Input() ariaHaspopup: boolean = true;
  @Input() ariaExpanded: boolean = false;

  @Input() dataCY: string;

  @Input() filterOption: boolean = false;
  @Input() spanClass!: string;

  @Output() buttonClick = new EventEmitter<any>();

  constructor(private navigateService: NavigateService, private cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.id = this.id ? this.id?.replace(/\W+/g, '_').toLowerCase() : this.text?.replace(/\W+/g, '-').toLowerCase();  

    if (this.altText == undefined) {
      this.altText = this.text;
    }

    if (this.iconClass && this.badgeText)
      throw new Error(
        'Please pass only one of these inputs - iconClass or badgeText'
      );
       
  
  }

  buttonAction(event: any) {
    if (this.buttonLayoutType == 'dropdown-item') {
      this.buttonClick.emit(event);
    } else {
      if (this.link) {
        this.navigateService.navigate(this.link, {target: this.target});
      } else {
        this.buttonClick.emit(event);
      }
    }
  }

  ngAfterViewInit() {
    if (this.buttonClass?.includes('squareStyle')) this.squaredStyle = true;
    this.isBold = this.buttonClass?.includes('fw-bold');
    
    this.cdRef.detectChanges();
  }

  getPopover(e){
    this.buttonClick.emit(e);
  }
}
