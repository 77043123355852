<ng-container [ngSwitch]="buttonLayoutType">

  <ng-container *ngSwitchCase="'link'">
    
    <button 
      [attr.ngbdropdowntoggle]="ngB == true ? '' : null"
      [id]="id" 
      [type]="type" 
      [attr.aria-label]="altText" 
      [attr.data-cy]="dataCY"
      target="_blank"      
      [class]="'btn' + (buttonColorClass ? (' btn-' + buttonColorClass) : '') + ' ' + (buttonClass ? buttonClass : '') + ' ' + (active == true ? ' active ' : '') + (disabled == true ? ' disabled ' : '') + (isHovered? buttonHoverClass : '')"
      [ngClass]="customNgClass" 
      [attr.active]="active" 
      [disabled]="disabled" 
      [ngStyle]="customNgStyle" 
      (click)="buttonAction($event)" 
      (mouseover)="isHovered = true"
      (mouseout)="isHovered = false">
        <ng-container *ngTemplateOutlet="button"></ng-container>
    </button>
  </ng-container>


  <ng-container *ngSwitchCase="'dropdown-button'">
    <button 
      [id]="id" 
      [type]="type" 
      [attr.data-cy]="dataCY"   
      [class]="'btn' + (buttonColorClass ? (' btn-' + buttonColorClass) : '') + ' ' + (buttonClass ? buttonClass : '') + ' ' + (iconLayout == true ? 'icon-badge ': '') + (active == true ? ' active ' : '') + (disabled == true ? ' disabled ' : '')"
      [class.dropdown-toggle]="!iconLayout" 
      [ngClass]="customNgClass" 
      [ngStyle]="customNgStyle" 
      (click)="buttonAction($event)">

        <ng-container *ngIf="!iconLayout; else templateI">
          <ng-container *ngTemplateOutlet="dropdown"></ng-container>
        </ng-container>
        <ng-template #templateI>
          <ng-container *ngTemplateOutlet="icon"></ng-container>
        </ng-template>
    </button>
  </ng-container>

  <!--Collapse button-->
  <ng-container *ngSwitchCase="'collapse-button'">
    <button 
      [id]="id" 
      [type]="type"
      [attr.data-cy]="dataCY"
      [class]="' btn' + (buttonColorClass ? (' btn-' + buttonColorClass) : '') + (iconLayout ? ' icon-badge ' : '') + (buttonClass ? buttonClass : '') +  (iconLayout ? ' icon-badge-'+ iconSize : '') + (active == true ? ' active ' : '') + (disabled == true ? ' disabled ' : '')"
      (click)="buttonAction($event)" 
      [attr.aria-expanded]="!isCollapsed">
        <ng-container *ngIf="!iconLayout; else templateI">
          <ng-container *ngTemplateOutlet="dropdown"></ng-container>
        </ng-container>
        <ng-template #templateI>
          <ng-container *ngTemplateOutlet="icon"></ng-container>
        </ng-template>
      </button>
  </ng-container>

  <ng-container *ngSwitchCase="'icon'">
   
    <button 
      [id]="id" 
      [type]="type" 
      [attr.aria-label]="altText"
      [attr.data-cy]="dataCY" 
      [attr.data-dismiss]="dataDismiss"
      [class]="'btn' + (buttonColorClass ? (' btn-' + buttonColorClass) : '')  + ' icon-badge '  + (buttonClass ? (buttonClass) : '') + (active == true ? ' active' : '') + (disabled == true ? ' disabled ' : '') + (iconSize ? ' icon-badge-'+ iconSize : '')"
      (click)="buttonAction($event)" 
      [ngStyle]="customNgStyle"
      [active]="active" [disabled]="disabled" 
      [ngClass]="customNgClass" 
      [style]="customStyle">
        <i *ngIf="iconClass && !badgeText" [class]="iconClass" [ngStyle]="iconStyle" [ngClass]="iconClass">
        </i>
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="'popover'">
    <button 
    [id]="id"
    type="button" 
    [attr.data-cy]="dataCY"
    [attr.aria-label]="'popover'" 
    [class]="'btn' + (buttonColorClass ? (' btn-' + buttonColorClass) : '') + ' icon-badge ' + (buttonClass ? (buttonClass) : '') + (iconSize ? 'icon-badge-'+ iconSize : '')"
    ngbPopover="{{popoverText}}"
    triggers="mouseenter:mouseleave" 
    [attr.placement]="buttonMenuPlacement ? buttonMenuPlacement : 'right'">
      <i [class]="'fas fa-info pop'" [ngStyle]="iconStyle" [ngClass]="'fas fa-info'"></i>
    </button>   
  </ng-container>
</ng-container>

<ng-template #icon>
  <i *ngIf="iconClass && !badgeText" [class]="iconClass" [ngStyle]="iconStyle" [ngClass]="isHovered ? iconHoverClass : ''"></i>
</ng-template>

<ng-template #button>
  <ng-container *ngIf="text || (iconClass && !badgeText)">
    <h4 class="mb-0 fw-bold" *ngIf="text" [ngClass]="iconClass && !badgeText? 'd-flex justify-content-between align-items-center gap-3' : ''">{{ text }}
      <i *ngIf="iconClass && !badgeText" [class]="iconClass" [ngClass]="isHovered? iconHoverClass : ''" [ngStyle]="iconStyle"></i>
    </h4>
  </ng-container>
  <ng-container *ngIf="badgeText">
    <h4 class="mb-0 fw-bold">{{badgeText}}</h4>
  </ng-container>
  <ng-container *ngIf="!badgeText && !text && iconClass">
    <i [class]="iconClass" [ngClass]="isHovered ? iconHoverClass : ''" [ngStyle]="iconStyle"></i>
  </ng-container>
</ng-template>

<ng-template #dropdown>
  <h4 [class]="'mb-0 ' + (isBold ? 'fw-bold ' : '')">{{ text }}</h4>
</ng-template>