
<div class="d-flex justify-content-between align-items-center">
  <p [class]="'text-truncate mb-0 fw-bold ' + labelClass + ' ' + (_popoverText ? ' popover-link ' : '')  + (labelColor ? labelColor : 'text-gray-base')">
    <ng-container *ngIf="_popoverText; else noPopover">
    <div class="d-flex align-items-center position-relative gap-2 popover-link" role="button" 
    (mouseenter)="showPopoverBadge = true" (mouseleave)="showPopoverBadge = false; p.close()">
      <ng-container [ngTemplateOutlet]="label"></ng-container>
      <a #p="ngbPopover"
        [ngbPopover]="_popoverText" 
        [popoverTitle]="_popoverTitle" 
        [placement]="['right', 'left']"
        container="body" class="stretched-link">
        <i class="fa-circle-info" [ngClass]="[(showPopoverBadge ? 'visible ': 'invisible '), (p?.isOpen() ? 'fa-solid text-primary':'fa-light text-gray-base')]" ></i>
      </a>
    </div>
    </ng-container>

    <ng-template #noPopover>
      <ng-container [ngTemplateOutlet]="label"></ng-container>
    </ng-template>    

    <ng-template #label>
      {{_label}}
      <span *ngIf="required && _label" class="text-danger">*</span> 
    </ng-template>
  </p>
  <fpc-button 
  *ngIf="_customErrors"
  [buttonLayoutType]="'popover-button'"
  [iconClass]="'fa-light fa-circle-exclamation'"
  [buttonClass]="'text-red'"
  ></fpc-button>
  
  <p *ngIf="_labelAdditionalInfo" class="h6 m-0 mb-2">{{_labelAdditionalInfo}}</p>  
</div>

<ng-content></ng-content>