import {
  Directive,
  Input,
  OnInit,
  OnDestroy,
  Injector
} from '@angular/core';
import {
  ControlValueAccessor,
  NgControl,
  FormControlName,
  FormGroupDirective,
  FormControlDirective,
  FormControl,
  ValidationErrors,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Directive()
export abstract class BaseInputComponent implements ControlValueAccessor, OnInit, OnDestroy {
  control: FormControl;

  id: string = (Math.floor(Math.random() * 100) + 1).toString();

  _label: string;

  _labelAdditionalInfo: string;

  _placeholder:string;

  _popoverTitle: string;

  _popoverText: string;

  _customErrors:any = [];

  onDestroy$ = new Subject();

  protected _onChange: any;

  protected _onTouch: (arg?: any) => void;

  protected _disabled: boolean = false;

  error: string;

  @Input() set label(value: string) {
    this._label = value;
    this.id = value?.replace(/\W+/g, '_').toLowerCase() + '_' + (Math.floor(Math.random() * 100) + 1).toString();
  }

  @Input() set labelAdditionalInfo(value: string) {
    this._labelAdditionalInfo = value;
  }

  @Input() set popoverText(value: string) {
    this._popoverText = value;
  }

  @Input() set popoverTitle(value: string) {
    this._popoverTitle = value;
  }

  @Input() formControl: FormControl = new FormControl();

  @Input() formControlName: string;

  @Input() set placeholder(value: string){
    this.setPlaceHolder(value);
  }

  @Input('disabled') public set disabled(value: boolean) {
    if (this._disabled === value) return;

    this._disabled = value;

    this._disabled ? this.formControl.disable() : this.formControl.enable();
  }

  public get disabled() {
    return this._disabled;
  }

  @Input() set customErrors(labels:any){
     this._customErrors = labels ? labels : [];
  }

  get customErrors(){
    return this._customErrors;
  }

  @Input() required: boolean; 

  constructor(private injector: Injector) { }

  ngOnInit(): void {
    this.setFormControl();
    this.checkRequired();

    this.formControl.valueChanges.subscribe((value) => {
      this.setPlaceHolder(this._placeholder);
    })
  }

  setPlaceHolder(value) {
    this._placeholder = value;
  }

  setFormControl() {
    try {
      const formControl = this.injector.get(NgControl);

      switch (formControl.constructor) {
        case FormControlName:
          this.control = this.injector
            .get(FormGroupDirective)
            .getControl(formControl as FormControlName);
          break;

        default:
          this.control = (formControl as FormControlDirective)
            .form as FormControl;
      }
    } catch (err) {
      this.control = new FormControl();
    }
  }

  writeValue(value: any): void {
    if (this.control) {
      if (this.control.value != value) {
        this.control.setValue(value);
      }
    } else {
      this.control = new FormControl(value);
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void { 
    this._onTouch = fn;   
  } 

  checkRequired(){
    if(this.required) return;

    let required = false;

    if(this.formControl.validator){
      const validationResult = this.formControl.validator(new FormControl());
      required  = (validationResult != undefined && validationResult != null) && validationResult.required === true;
    }
    this.required = required;
  }

  validate(control: FormControl): ValidationErrors | undefined {
    if(!this.disabled){
      return this.error as any;
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }
}
